// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'


export const historyCreate = createAsyncThunk('history/application/create', async (params) => {
  const response = await apiHandle('POST', 'application/paybalance', params)
  return response
})

export const historyPDFCreate = createAsyncThunk('history/application/pdfpayment/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdfpayment', params)
  return response
})

export const historyReaderCard = createAsyncThunk('history/historyReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const getDataTable = createAsyncThunk('history/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'exam/gettable', params)
  return response
})

export const historyDataDetail = createAsyncThunk('history/historyDataDetail', async (params) => {
  const response = await apiHandle('GET', 'application/get', params)
  return response
})

export const historyGetCourse = createAsyncThunk('history/historyGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforhistory', params)
  return response
})

export const getAllData = createAsyncThunk('history/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('history/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('history/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('history/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('history/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultCoruseCost = {
  course_id: '',
  course_name: null,
  promotion_code: null,
  history_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultOtherCost = {
  other_fee_id: null,
  other_fee_name: null,
  promotion_code: null,
  history_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultPayments = {
  payment_id: null,
  payment_code: null,
  pay_type: null,
  paid: null,
  createdAt: null
}


const defaultReisterDetail = {
  is_foreigner: false,
  prefix: '',
  name: '',
  idcard: '',
  birthday: null,
  address: null,
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  age: '',
  course: [defaultCoruseCost],
  other_fee: [defaultOtherCost],
  payments: [defaultPayments],
  paid: '',
  discount: '',
  net: '',
  balance: '',
  total: '',
  // pay_type: 'เงินสด',
  status: null,
  application_code: null,
  course_id: '',
  createStatus: false,
  application_id: null,
  pdf: []
}


export const historySlice = createSlice({
  name: 'history',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    historyData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading:false
    },
    historyDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      userData: defaultReisterDetail
    },
    alert:{
      active:false,
      message:''
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'history/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    historyNew: (state, action) => {
      if (action.type === 'history/historyNew') {
        state.historyDetail.isEdit = false
        state.historyDetail.userData = defaultReisterDetail
      }
    },
    historyAddHandle: (state, action) => {
      if (action.type === 'history/historyAddHandle') {
        if (action.payload.target === 'course') {
          state.historyDetail.userData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.historyDetail.userData.other_fee.push(defaultOtherCost)
        }

      }
    },
    historyDeleteHandle: (state, action) => {
      if (action.type === 'history/historyDeleteHandle') {
        if (action.payload.target === 'course') {
          state.historyDetail.userData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.historyDetail.userData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    historyDropdownHandle: (state, action) => {
      if (action.type === 'history/historyDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.historyDetail.isEdit === false) {
          state.historyDetail.userData.course[action.payload.index].course_id = action.payload.value.course_id
          state.historyDetail.userData.course[action.payload.index].course_name = action.payload.value.name
          state.historyDetail.userData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'historyDate') {
          state.historyDetail.userData.course[action.payload.index].history_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.historyDetail.isEdit === false) {
          state.historyDetail.userData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.historyDetail.userData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.historyDetail.userData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.historyDetail.userData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    historyOnChangeHandle: (state, action) => {
      if (action.type === 'history/historyOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.historyDetail.userData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.historyDetail.userData[action.payload.target] = action.payload.value
        }

      }
    },
    historyLoading: (state, action) => {
      if (action.type === 'history/historyLoading') {
        state.historyData.isLoading = true
      }
    }
    
  },
  extraReducers: builder => {
    builder
      .addCase(historyCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'history/application/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            // state.historyDetail.userData.createStatus = true
            // state.historyDetail.userData.pdf = action.payload.data
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'history/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.historyData = action.payload
            state.historyData.isLoading = false
          }
        }
      })
      .addCase(historyDataDetail.fulfilled, (state, action) => {
        if (action.type === 'history/historyDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.historyDetail.isEdit = true
            state.historyDetail.userData.prefix = action.payload.data.customer.prefix ? action.payload.data.customer.prefix : ''
            state.historyDetail.userData.name = action.payload.data.customer.name ? action.payload.data.customer.name : ''
            state.historyDetail.userData.idcard = action.payload.data.customer.idcard ? action.payload.data.customer.idcard : ''
            state.historyDetail.userData.birthday = action.payload.data.customer.birthday ? action.payload.data.customer.birthday : null
            state.historyDetail.userData.address = action.payload.data.customer.address ? action.payload.data.customer.address : ''
            state.historyDetail.userData.moo = action.payload.data.customer.moo ? action.payload.data.customer.moo : ''
            state.historyDetail.userData.soi = action.payload.data.customer.soi ? action.payload.data.customer.soi : ''
            state.historyDetail.userData.street = action.payload.data.customer.street ? action.payload.data.customer.street : ''
            state.historyDetail.userData.province = action.payload.data.customer.province ? action.payload.data.customer.province : ''
            state.historyDetail.userData.district = action.payload.data.customer.district ? action.payload.data.customer.district : ''
            state.historyDetail.userData.subdistrict = action.payload.data.customer.subdistrict ? action.payload.data.customer.subdistrict : ''
            state.historyDetail.userData.phone_1 = action.payload.data.customer.phone_1 ? action.payload.data.customer.phone_1 : ''
            state.historyDetail.userData.phone_2 = action.payload.data.customer.phone_2 ? action.payload.data.customer.phone_2 : ''
            state.historyDetail.userData.status = action.payload.data.status ? action.payload.data.status : null
            state.historyDetail.userData.application_code = action.payload.data.application_code ? action.payload.data.application_code : null
            state.historyDetail.userData.paid = action.payload.data.paid === 0 ? '0' : action.payload.data.paid.toString()
            state.historyDetail.userData.course_id = action.payload.data.paid ? action.payload.data.course_id.toString() : ''
            state.historyDetail.userData.discount = action.payload.data.discount === 0 ? '0' : action.payload.data.discount.toString()
            state.historyDetail.userData.net = action.payload.data.net === 0 ? '0' : action.payload.data.net.toString()
            state.historyDetail.userData.balance = action.payload.data.balance === 0 ? '0' : action.payload.data.balance.toString()
            state.historyDetail.userData.total = action.payload.data.total === 0 ? '0' : action.payload.data.total.toString()
            state.historyDetail.userData.payments = action.payload.data.payments
            state.historyDetail.userData.application_id = action.payload.data.application_id
            state.historyDetail.userData.course = [
              {
                course_id: action.payload.data.course_id ? action.payload.data.course_id.toString() : '',
                course_name: null,
                promotion_code: null,
                history_date: null,
                cost: action.payload.data.balance ? action.payload.data.balance.toString() : '',
                discount: null,
                paid: null,
                pay_type: null
              }
            ]
          }
        }
      })
      .addCase(historyGetCourse.fulfilled, (state, action) => {
        if (action.type === 'history/historyGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.historyDetail.dropdown.course = action.payload.course
            state.historyDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(historyReaderCard.fulfilled, (state, action) => {
        if (action.type === 'history/historyReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.historyDetail.userData.prefix = action.payload.title_th
            state.historyDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.historyDetail.userData.idcard = action.payload.nat_id
            state.historyDetail.userData.birthday = action.payload.birthdate
            state.historyDetail.userData.address = action.payload.address_no
            state.historyDetail.userData.street = action.payload.address_road
            state.historyDetail.userData.province = action.payload.address_provinice
            state.historyDetail.userData.district = action.payload.address_amphor
            state.historyDetail.userData.subdistrict = action.payload.address_tumbol
            state.historyDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(historyPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'history/application/pdfpayment/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, historyNew, historyAddHandle, historyDropdownHandle, historyDeleteHandle, historyOnChangeHandle, historyLoading } = historySlice.actions

export default historySlice.reducer
