// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'

export const otherCreate = createAsyncThunk('other/applicationfee/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/create', params)
  return response
})
export const getDataTable = createAsyncThunk('other/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'applicationfee/gettable', params)
  return response
})
export const otherReaderCard = createAsyncThunk('other/otherReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})
export const otherCheckExsit = createAsyncThunk('other/otherCheckExsit', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const otherDataDetail = createAsyncThunk('other/otherDataDetail', async (params) => {
  const response = await apiHandle('GET', 'applicationfee/get', params)
  return response
})

export const otherGetCourse = createAsyncThunk('other/otherGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforregister', params)
  return response
})

export const otherPDFPaymentCreate = createAsyncThunk('other/applicationfee/pdfpayment/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/pdfpayment', params)
  return response
})

export const otherPDFCreate = createAsyncThunk('other/applicationfee/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/pdf', params)
  return response
})


const defaultCoruseCost = {
  course_id: '',
  course_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultOtherCost = {
  other_fee_id: null,
  other_fee_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultPayments = {
  payment_id: null,
  payment_code: null,
  pay_type: null,
  paid: null,
  createdAt: null
}


const defaultReisterDetail = {
  is_foreigner: false,
  prefix: '',
  name: '',
  idcard: '',
  birthday: null,
  address: null,
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  age: '',
  course: [defaultCoruseCost],
  other_fee: [defaultOtherCost],
  payments: [defaultPayments],
  paid: '',
  discount: '',
  net: '',
  balance: '',
  total: '',
  // pay_type: 'เงินสด',
  status: null,
  application_code: null,
  other_fee_id: '',
  createStatus: false,
  application_id: null,
  application_other_fee_code:null
}


export const otherSlice = createSlice({
  name: 'other',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedData: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    otherData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    otherDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      userData: defaultReisterDetail
    },
    alert:{
      active:false,
      message:''
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'other/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    otherNew: (state, action) => {
      if (action.type === 'other/otherNew') {
        state.otherDetail.isEdit = false
        state.otherDetail.userData = defaultReisterDetail
      }
    },
    otherAddHandle: (state, action) => {
      console.log(action)
      if (action.type === 'other/otherAddHandle') {
        if (action.payload.target === 'course') {
          state.otherDetail.userData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.otherDetail.userData.other_fee.push(defaultOtherCost)
        }

      }
    },
    otherDeleteHandle: (state, action) => {
      if (action.type === 'other/otherDeleteHandle') {
        if (action.payload.target === 'course') {
          state.otherDetail.userData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.otherDetail.userData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    otherDropdownHandle: (state, action) => {
      if (action.type === 'other/otherDropdownHandle') {
        if (action.payload.target === 'course' && state.otherDetail.isEdit === false) {
          state.otherDetail.userData.course[action.payload.index].course_id = action.payload.value.course_id
          state.otherDetail.userData.course[action.payload.index].course_name = action.payload.value.name
          state.otherDetail.userData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.otherDetail.userData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.otherDetail.isEdit === false) {
          state.otherDetail.userData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.otherDetail.userData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.otherDetail.userData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.otherDetail.userData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    otherOnChangeHandle: (state, action) => {
      if (action.type === 'other/otherOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.otherDetail.userData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.otherDetail.userData[action.payload.target] = action.payload.value
        }

      }
    },
    otherLoading: (state, action) => {
      if (action.type === 'other/otherLoading') {
        state.otherData.isLoading = true
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDataTable.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'other/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.otherData = action.payload
            state.otherData.isLoading = false
          }
        }
      })
      .addCase(otherReaderCard.fulfilled, (state, action) => {
        if (action.type === 'other/otherReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.otherDetail.userData.prefix = action.payload.title_th
            state.otherDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.otherDetail.userData.idcard = action.payload.nat_id
            state.otherDetail.userData.birthday = action.payload.birthdate
            state.otherDetail.userData.address = action.payload.address_no
            state.otherDetail.userData.street = action.payload.address_road
            state.otherDetail.userData.province = action.payload.address_provinice
            state.otherDetail.userData.district = action.payload.address_amphor
            state.otherDetail.userData.subdistrict = action.payload.address_tumbol
            state.otherDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(otherDataDetail.fulfilled, (state, action) => {
        if (action.type === 'other/otherDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.otherDetail.isEdit = true
            state.otherDetail.userData.prefix = action.payload.data.customer.prefix ? action.payload.data.customer.prefix : ''
            state.otherDetail.userData.name = action.payload.data.customer.name ? action.payload.data.customer.name : ''
            state.otherDetail.userData.idcard = action.payload.data.customer.idcard ? action.payload.data.customer.idcard : ''
            state.otherDetail.userData.birthday = action.payload.data.customer.birthday ? action.payload.data.customer.birthday : null
            state.otherDetail.userData.address = action.payload.data.customer.address ? action.payload.data.customer.address : ''
            state.otherDetail.userData.moo = action.payload.data.customer.moo ? action.payload.data.customer.moo : ''
            state.otherDetail.userData.soi = action.payload.data.customer.soi ? action.payload.data.customer.soi : ''
            state.otherDetail.userData.street = action.payload.data.customer.street ? action.payload.data.customer.street : ''
            state.otherDetail.userData.province = action.payload.data.customer.province ? action.payload.data.customer.province : ''
            state.otherDetail.userData.district = action.payload.data.customer.district ? action.payload.data.customer.district : ''
            state.otherDetail.userData.subdistrict = action.payload.data.customer.subdistrict ? action.payload.data.customer.subdistrict : ''
            state.otherDetail.userData.phone_1 = action.payload.data.customer.phone_1 ? action.payload.data.customer.phone_1 : ''
            state.otherDetail.userData.phone_2 = action.payload.data.customer.phone_2 ? action.payload.data.customer.phone_2 : ''
            state.otherDetail.userData.status = action.payload.data.status ? action.payload.data.status : null
            state.otherDetail.userData.application_other_fee_code = action.payload.data.application_other_fee_code ? action.payload.data.application_other_fee_code : null
            state.otherDetail.userData.paid = action.payload.data.paid === 0 ? '0' : action.payload.data.paid.toString()
            state.otherDetail.userData.other_fee_id = action.payload.data.other_fee_id ? action.payload.data.other_fee_id.toString() : ''
            state.otherDetail.userData.discount = action.payload.data.discount === 0 ? '0' : action.payload.data.discount.toString()
            state.otherDetail.userData.net = action.payload.data.net === 0 ? '0' : action.payload.data.net.toString()
            state.otherDetail.userData.balance = action.payload.data.balance === 0 ? '0' : action.payload.data.balance.toString()
            state.otherDetail.userData.total = action.payload.data.total === 0 ? '0' : action.payload.data.total.toString()
            state.otherDetail.userData.application_id = action.payload.data.application_other_fee_id
            state.otherDetail.userData.payments = action.payload.data.payments
            state.otherDetail.userData.other_fee = [
              {
                other_fee_id: action.payload.data.other_fee_id ? action.payload.data.other_fee_id.toString() : '',
                other_fee_name: null,
                promotion_code: null,
                exam_date: null,
                cost: action.payload.data.balance ? action.payload.data.balance.toString() : '',
                discount: null,
                paid: null,
                pay_type: null
              }
            ]
          }
        }
      })
      .addCase(otherCheckExsit.fulfilled, (state, action) => {
        if (action.type === 'other/otherCheckExsit/fulfilled') {
          console.log(action)
          if (action.payload.status === 'success') {
            if (action.payload.data) {
              state.otherDetail.userData.prefix = action.payload.data.prefix ? action.payload.data.prefix : ''
              state.otherDetail.userData.name = action.payload.data.name ? action.payload.data.name : ''
              state.otherDetail.userData.idcard = action.payload.data.idcard ? action.payload.data.idcard : ''
              state.otherDetail.userData.birthday = action.payload.data.birthday ? action.payload.data.birthday : null
              state.otherDetail.userData.address = action.payload.data.address ? action.payload.data.address : ''
              state.otherDetail.userData.moo = action.payload.data.moo ? action.payload.data.moo : ''
              state.otherDetail.userData.soi = action.payload.data.soi ? action.payload.data.soi : ''
              state.otherDetail.userData.street = action.payload.data.street ? action.payload.data.street : ''
              state.otherDetail.userData.province = action.payload.data.province ? action.payload.data.province : ''
              state.otherDetail.userData.district = action.payload.data.district ? action.payload.data.district : ''
              state.otherDetail.userData.subdistrict = action.payload.data.subdistrict ? action.payload.data.subdistrict : ''
              state.otherDetail.userData.phone_1 = action.payload.data.phone_1 ? action.payload.data.phone_1 : ''
              state.otherDetail.userData.phone_2 = action.payload.data.phone_2 ? action.payload.data.phone_2 : ''
            } else {

            }

          } else {
            //handleError
          }
        }
      })
      .addCase(otherGetCourse.fulfilled, (state, action) => {
        if (action.type === 'other/otherGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.otherDetail.dropdown.course = action.payload.course
            state.otherDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(otherPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'other/applicationfee/pdf/create/fulfilled') {
          console.log(action)
          if (action.payload.status === 'success') {
            console.log(action)
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(otherPDFPaymentCreate.fulfilled, (state, action) => {
        if (action.type === 'other/applicationfee/pdfpayment/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
  }
})

export const { searchCriteria, otherNew, otherAddHandle, otherDropdownHandle, otherDeleteHandle, otherOnChangeHandle, otherLoading } = otherSlice.actions

export default otherSlice.reducer
