// ** Axios Imports
import axios from 'axios'
// ** Third Party Components

// const url = 'https://ktytech.com/best/api/'

// const tokenHandle = () => {
//     const authentication = window.localStorage.getItem('userData')
//     // console.log("Authentication : ", JSON.parse(authentication).access_token)
//     return authentication ? JSON.parse(authentication).access_token : null
// }

// const apiHandle = async (method, path, params) => {

//     const config = {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Authorization: `Bearer ${tokenHandle()}`
//         }
//     }
//     if (method === 'POST') {
//         try {
//             const response = await axios.post(url + path, params, config)
//             console.log(`${method} ${path} response : `, response)
//             return response.data
//         } catch (err) {
//             return err.response.data
//         }
//     }
//     if (method === 'GET') {
//         if (params) {
//             if (path === 'application/get' || path === 'applicationfee/get') {
//                 path = `${path}/${params}`
//             } else {
//                 path = `${path}?${new URLSearchParams(params)}`
//             }

//         }

//         try {
//             const response = await axios.get(url + path, config)
//             console.log(`${method} ${path} response : `, response)
//             return response.data
//         } catch (err) {
//             console.log(err)
//         }

//     }

// }

const url = process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://ktytech.com/best/api/'

const tokenHandle = () => {

    const authentication = window.localStorage.getItem('rootState')
    // console.log("Authentication : ", JSON.parse(authentication)?.testing?.auth?.access_token)
    return authentication ? JSON.parse(authentication)?.testing?.auth?.access_token : null
}

const refreshToken = async () => {
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem('rootState')
    window.location = '/login'
    // const config = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json;charset=UTF-8',
    //         Authorization: `Bearer ${JSON.parse(window.localStorage.getItem('userData')).access_token}`
    //     },
    //     body: JSON.stringify({
    //         refreshToken: JSON.parse(window.localStorage.getItem('userData')).refresh_token
    //     })
    // }
    // const response = await fetch(`${url}user/refresh`, config)
    // if (response.status >= 400) {
    //     // error
    //     window.localStorage.removeItem('userData')
    //     window.localStorage.removeItem('rootState')
    //     window.location = '/login'
    //     return false
    // } else {
    //     const res = await response.json()
    //     //success
    //     return true
    // }

}

const apiHandle = async (method, path, params) => {

    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${tokenHandle()}`
        }
    }
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        config = {
            method: method,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${tokenHandle()}`
            },
            body: JSON.stringify(params)
        }
        const response = await fetch(url + path, config)
        if (response.status > 400) {
            // error
            const refresh = await refreshToken()
            if (refresh) {
                return await apiHandle(method, path, params)
            }
        } else {
            const res = await response.json()
            console.log(`${method} ${path} response : `, res)
            return res
        }
    }
    if (method === 'GET') {
        if (params) {
            if (path === 'application/get' ||
                path === 'applicationfee/get' ||
                path === 'user/get' ||
                path === 'course/get' ||
                path === 'otherfee/get' ||
                path === 'promotion/get' ||
                path === 'branch/get' ||
                path === 'vehicle/get' ||
                path === 'list-payroll/get' ||
                path === 'payroll/user' ||
                path === 'list-expenses/get' ||
                path === 'income/get' ||
                path === 'expenses/get' ||
                path === 'list-expenses/get'
            ) {
                path = `${path}/${params}`
            } else {
                path = `${path}?${new URLSearchParams(params)}`
            }
        }
        // const response = await axios.get(url + path, config)
        const response = await fetch(url + path, config)

        if (response.status > 400) {
            // error
            const refresh = await refreshToken()
            if (refresh) {
                return await apiHandle(method, path, params)
            }
            // window.localStorage.removeItem('userData')
            // window.localStorage.removeItem('rootState')
            // window.localStorage.href('rootState')
            // navigate('www.google.com')
            // history.push('/login')
        } else {
            const res = await response.json()
            console.log(`${method} ${path} response : `, res)
            return res
        }
    }

}

export default apiHandle
