// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

import apiHandle from '../configs/apiConfig'

export const loginSubmit = createAsyncThunk('user/login', async (params) => {
  const response = await apiHandle('POST', 'user/login', params)
  console.log('response', response)
  return response

})
const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : null
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    message: null
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = null
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleError: (state, action) => {
      if (action.type === 'authentication/handleError') {
        state.message = null
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginSubmit.fulfilled, (state, action) => {
        console.log(state)
        if (action.type === 'user/login/fulfilled') {
          if (action.payload.status === 'error') {

            state.message = action.payload.message
          } else {
            const obj = {
              ...action.payload.data,
              ability: [
                {
                  action: 'manage',
                  subject: 'all'
                }
              ]
            }
            localStorage.setItem('userData', JSON.stringify(obj))
            state.userData = obj
          }
        }
        // state.suggestions = action.payload.data
        // state.bookmarks = action.payload.bookmarks
      })
  }
})

export const { handleLogin, handleLogout, handleError } = authSlice.actions

export default authSlice.reducer
