// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'


export const permissionCreate = createAsyncThunk('permission/application/create', async (params) => {
  const response = await apiHandle('POST', 'application/paybalance', params)
  return response
})


export const permissionPDFCreate = createAsyncThunk('permission/application/pdfpayment/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdfpayment', params)
  return response
})

export const permissionReaderCard = createAsyncThunk('permission/permissionReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const getDataTable = createAsyncThunk('permission/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'exam/permission', params)
  return response
})

export const permissionExtend = createAsyncThunk('permission/exam/extend', async (params) => {
  const response = await apiHandle('POST', 'exam/extend', params)
  return response
})

export const permissionDisable = createAsyncThunk('permission/exam/disable', async (params) => {
  const response = await apiHandle('POST', 'exam/disable', params)
  return response
})

export const permissionDataDetail = createAsyncThunk('permission/permissionDataDetail', async (params) => {
  const response = await apiHandle('GET', 'application/get', params)
  return response
})

export const permissionGetCourse = createAsyncThunk('permission/permissionGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforpermission', params)
  return response
})

export const getAllData = createAsyncThunk('permission/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('permission/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('permission/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('permission/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('permission/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultCoruseCost = {
  course_id: '',
  course_name: null,
  promotion_code: null,
  permission_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultOtherCost = {
  other_fee_id: null,
  other_fee_name: null,
  promotion_code: null,
  permission_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultPayments = {
  payment_id: null,
  payment_code: null,
  pay_type: null,
  paid: null,
  createdAt: null
}


const defaultReisterDetail = {
  is_foreigner: false,
  prefix: '',
  name: '',
  idcard: '',
  birthday: null,
  address: null,
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  age: '',
  course: [defaultCoruseCost],
  other_fee: [defaultOtherCost],
  payments: [defaultPayments],
  paid: '',
  discount: '',
  net: '',
  balance: '',
  total: '',
  // pay_type: 'เงินสด',
  status: null,
  application_code: null,
  course_id: '',
  createStatus: false,
  application_id: null,
  pdf: []
}


export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    permissionData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    permissionDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      userData: defaultReisterDetail
    },
    alert: {
      active: false,
      message: ''
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'permission/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    permissionNew: (state, action) => {
      if (action.type === 'permission/permissionNew') {
        state.permissionDetail.isEdit = false
        state.permissionDetail.userData = defaultReisterDetail
      }
    },
    permissionAddHandle: (state, action) => {
      if (action.type === 'permission/permissionAddHandle') {
        if (action.payload.target === 'course') {
          state.permissionDetail.userData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.permissionDetail.userData.other_fee.push(defaultOtherCost)
        }

      }
    },
    permissionDeleteHandle: (state, action) => {
      if (action.type === 'permission/permissionDeleteHandle') {
        if (action.payload.target === 'course') {
          state.permissionDetail.userData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.permissionDetail.userData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    permissionDropdownHandle: (state, action) => {
      if (action.type === 'permission/permissionDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.permissionDetail.isEdit === false) {
          state.permissionDetail.userData.course[action.payload.index].course_id = action.payload.value.course_id
          state.permissionDetail.userData.course[action.payload.index].course_name = action.payload.value.name
          state.permissionDetail.userData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'permissionDate') {
          state.permissionDetail.userData.course[action.payload.index].permission_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.permissionDetail.isEdit === false) {
          state.permissionDetail.userData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.permissionDetail.userData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.permissionDetail.userData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.permissionDetail.userData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    permissionOnChangeHandle: (state, action) => {
      if (action.type === 'permission/permissionOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.permissionDetail.userData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.permissionDetail.userData[action.payload.target] = action.payload.value
        }

      }
    },
    permissionLoading: (state, action) => {
      if (action.type === 'permission/permissionLoading') {
        state.permissionData.isLoading = true
      }
    },
    permissionAlert: (state, action) => {
      if (action.type === 'permission/permissionAlert') {
        state.alert.active = false
        state.alert.message = ''
      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(permissionCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'permission/application/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            // state.permissionDetail.userData.createStatus = true
            // state.permissionDetail.userData.pdf = action.payload.data
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'permission/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.permissionData = action.payload
            state.permissionData.isLoading = false
          }
        }
      })
      .addCase(permissionDataDetail.fulfilled, (state, action) => {
        if (action.type === 'permission/permissionDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.permissionDetail.isEdit = true
            state.permissionDetail.userData.prefix = action.payload.data.customer.prefix ? action.payload.data.customer.prefix : ''
            state.permissionDetail.userData.name = action.payload.data.customer.name ? action.payload.data.customer.name : ''
            state.permissionDetail.userData.idcard = action.payload.data.customer.idcard ? action.payload.data.customer.idcard : ''
            state.permissionDetail.userData.birthday = action.payload.data.customer.birthday ? action.payload.data.customer.birthday : null
            state.permissionDetail.userData.address = action.payload.data.customer.address ? action.payload.data.customer.address : ''
            state.permissionDetail.userData.moo = action.payload.data.customer.moo ? action.payload.data.customer.moo : ''
            state.permissionDetail.userData.soi = action.payload.data.customer.soi ? action.payload.data.customer.soi : ''
            state.permissionDetail.userData.street = action.payload.data.customer.street ? action.payload.data.customer.street : ''
            state.permissionDetail.userData.province = action.payload.data.customer.province ? action.payload.data.customer.province : ''
            state.permissionDetail.userData.district = action.payload.data.customer.district ? action.payload.data.customer.district : ''
            state.permissionDetail.userData.subdistrict = action.payload.data.customer.subdistrict ? action.payload.data.customer.subdistrict : ''
            state.permissionDetail.userData.phone_1 = action.payload.data.customer.phone_1 ? action.payload.data.customer.phone_1 : ''
            state.permissionDetail.userData.phone_2 = action.payload.data.customer.phone_2 ? action.payload.data.customer.phone_2 : ''
            state.permissionDetail.userData.status = action.payload.data.status ? action.payload.data.status : null
            state.permissionDetail.userData.application_code = action.payload.data.application_code ? action.payload.data.application_code : null
            state.permissionDetail.userData.paid = action.payload.data.paid === 0 ? '0' : action.payload.data.paid.toString()
            state.permissionDetail.userData.course_id = action.payload.data.paid ? action.payload.data.course_id.toString() : ''
            state.permissionDetail.userData.discount = action.payload.data.discount === 0 ? '0' : action.payload.data.discount.toString()
            state.permissionDetail.userData.net = action.payload.data.net === 0 ? '0' : action.payload.data.net.toString()
            state.permissionDetail.userData.balance = action.payload.data.balance === 0 ? '0' : action.payload.data.balance.toString()
            state.permissionDetail.userData.total = action.payload.data.total === 0 ? '0' : action.payload.data.total.toString()
            state.permissionDetail.userData.payments = action.payload.data.payments
            state.permissionDetail.userData.application_id = action.payload.data.application_id
            state.permissionDetail.userData.course = [
              {
                course_id: action.payload.data.course_id ? action.payload.data.course_id.toString() : '',
                course_name: null,
                promotion_code: null,
                permission_date: null,
                cost: action.payload.data.balance ? action.payload.data.balance.toString() : '',
                discount: null,
                paid: null,
                pay_type: null
              }
            ]
          }
        }
      })
      .addCase(permissionGetCourse.fulfilled, (state, action) => {
        if (action.type === 'permission/permissionGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.permissionDetail.dropdown.course = action.payload.course
            state.permissionDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(permissionReaderCard.fulfilled, (state, action) => {
        if (action.type === 'permission/permissionReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.permissionDetail.userData.prefix = action.payload.title_th
            state.permissionDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.permissionDetail.userData.idcard = action.payload.nat_id
            state.permissionDetail.userData.birthday = action.payload.birthdate
            state.permissionDetail.userData.address = action.payload.address_no
            state.permissionDetail.userData.street = action.payload.address_road
            state.permissionDetail.userData.province = action.payload.address_provinice
            state.permissionDetail.userData.district = action.payload.address_amphor
            state.permissionDetail.userData.subdistrict = action.payload.address_tumbol
            state.permissionDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(permissionPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'permission/application/pdfpayment/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(permissionExtend.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'permission/exam/extend/fulfilled') {
          if (action.payload.status === 'success') {
            state.alert.active = true
            state.alert.message = action.payload.message
            console.log(getDataTable({
              page: state.permissionData.meta.page !== undefined ? state.permissionData.meta.page : 1,
              per_page: state.permissionData.meta.per_page !== undefined ? state.permissionData.meta.per_page : 10,
              type: state.searchCriteria.type.value,
              branch: state.searchCriteria.branch.value,
              status: state.searchCriteria.status.value,
              text: state.searchCriteria.text
            }))

          } else {
            //handleError
          }
        }
      })
      .addCase(permissionDisable.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'permission/exam/disable/fulfilled') {
          if (action.payload.status === 'success') {
            state.alert.active = true
            state.alert.message = action.payload.message
            getDataTable({
              page: state.permissionData.meta.page !== undefined ? state.permissionData.meta.page : 1,
              per_page: state.permissionData.meta.per_page !== undefined ? state.permissionData.meta.per_page : 10,
              type: state.searchCriteria.type.value,
              branch: state.searchCriteria.branch.value,
              status: state.searchCriteria.status.value,
              text: state.searchCriteria.text
            })
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, permissionNew, permissionAddHandle, permissionDropdownHandle, permissionDeleteHandle, permissionOnChangeHandle, permissionLoading, permissionAlert } = permissionSlice.actions

export default permissionSlice.reducer
