// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

const serializedState = localStorage.getItem('rootState')

const loadState = () => {
  try {
    console.log('loadState : ', JSON.parse(serializedState).register.registerDetail.userData)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('rootState', serializedState)
  } catch {
    // ignore write errors
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  },
  preloadedState: loadState()
})

store.subscribe(() => {
    saveState(store.getState())
})


export { store }