// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../configs/apiConfig'

// export const registerReaderCard = createAsyncThunk('register/registerReaderCard', async () => {
//   const response = await apiHandle('POST', 'mockreadercard')
//   return response
// })

// export const getDataTable = createAsyncThunk('register/getDataTable', async (params) => {
//   const response = await apiHandle('GET', 'application/gettable', params)
//   return response
// })
// customer/check
export const customerCheck = createAsyncThunk('evaluate/customerCheck', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const customerEvaluate = createAsyncThunk('customer/getapp/evaluate', async (params) => {
  const response = await apiHandle('GET', 'customer/getapp/evaluate', params)
  return response
})

export const getEvaluate = createAsyncThunk('customer/evaluate', async (params) => {
  const response = await apiHandle('GET', 'customer/evaluate', params)
  return response
})

export const submitEvaluate = createAsyncThunk('customer/submitevaluate', async (params) => {
  const response = await apiHandle('POST', 'customer/submitevaluate', params)
  return response
})

export const getEvaluateList = createAsyncThunk('customer/listevaluate', async (params) => {
  const response = await apiHandle('GET', 'customer/listevaluate', params)
  return response
})

export const customerLogin = createAsyncThunk('evaluate/customer/login', async (params) => {
  const response = await apiHandle('POST', 'customer/login', params)
  return response
})

export const customerExam = createAsyncThunk('evaluate/customerExam', async (params) => {
  const response = await apiHandle('GET', 'customer/exam', params)
  return response
})

export const customerCheckExam = createAsyncThunk('evaluate/customerCheckExam', async (params) => {
  const response = await apiHandle('POST', 'customer/checkresultexam', params)
  return response
})


export const evaluateSlice = createSlice({
  name: 'evaluate',
  initialState: {
    auth: null,
    evaluateData: null,
    evaluateState: null,
    sizing: 0,
    data: [],
    isReady: false,
    application_id: null,
    result: null,
    detail: null,
    alert: {
      active: false,
      message: '',
      err: false
    },
    evaluateSubmit: {
      application_id: null,
      idcard: null,
      user_id: null,
      tab: null,
      answer: []
    },
    trainer: [],
    trainerList: [],
    needCheck: false
  },
  reducers: {
    sizingHandle: (state, action) => {
      if (action.type === 'evaluate/sizingHandle') {
        console.log(action)
        state.sizing = action.payload
      }
    },
    clearAuth: (state, action) => {
      if (action.type === 'evaluate/clearAuth') {
        state.auth = null
      }
    },
    clearEvaluate: (state, action) => {
      if (action.type === 'evaluate/clearEvaluate') {
        state.evaluateData = null
      }
    },
    setReady: (state, action) => {
      if (action.type === 'evaluate/setReady') {
        state.isReady = action.payload
      }
    },
    setAnswer: (state, action) => {
      if (action.type === 'evaluate/setAnswer') {
        if (action.payload.index && action.payload.value) {
          state.data[action.payload.index - 1].client_answer = action.payload.value
        }
      }
    },
    evaluateAlert: (state, action) => {
      if (action.type === 'evaluate/evaluateAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    evaluateSubmit: (state, action) => {
      if (action.type === 'evaluate/evaluateSubmit') {
        state.evaluateSubmit = action.payload
      }
    },
    evaluateState: (state, action) => {
      if (action.type === 'evaluate/evaluateState') {
        state.evaluateState = action.payload
      }
    },
    needCheck: (state, action) => {
      console.log(action)
      if (action.type === 'evaluate/needCheck') {
        state.needCheck = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(customerCheck.fulfilled, (state, action) => {
        if (action.type === 'evaluate/customerCheck/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            alert(action?.payload?.message)
          } else {
            if (action.payload.data === null) {
              state.auth = undefined
            } else {
              state.auth = action.payload.data
            }

          }
        }
      })
      .addCase(customerLogin.fulfilled, (state, action) => {
        if (action.type === 'evaluate/customer/login/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            alert(action?.payload?.message)
          } else {
            if (action.payload.data === null) {
              state.auth = undefined
            } else {
              console.log(action.payload)
              state.auth = action.payload.data
            }

          }
        }
      })
      .addCase(customerEvaluate.fulfilled, (state, action) => {
        if (action.type === 'customer/getapp/evaluate/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            alert(action?.payload?.message)
          } else {
            state.evaluateData = action.payload.data
            if (!action.payload.data?.is_evaluate_trainer && !state.needCheck) {
              state.evaluateState = 'trainer'
              return
            } if (!action.payload.data?.is_evaluate_pr  && !state.needCheck) {
              state.evaluateState = 'pr'
              return
            } if (!action.payload.data?.is_evaluate_school  && !state.needCheck) {
              state.evaluateState = 'school'
              return
            }
          }
        }
      })
      .addCase(submitEvaluate.fulfilled, (state, action) => {
        if (action.type === 'customer/submitevaluate/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action?.payload?.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action?.payload?.message
            state.alert.err = false
          }
        }
      })
      .addCase(getEvaluateList.fulfilled, (state, action) => {
        if (action.type === 'customer/listevaluate/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            alert(action?.payload?.message)
          } else {
            state.trainerList = action.payload.data
          }
        }
      })
      .addCase(getEvaluate.fulfilled, (state, action) => {
        if (action.type === 'customer/evaluate/fulfilled') {

          if (action.payload.status === 'error') {
            //handleError
            alert(action?.payload?.message)
          } else {
            if (action?.meta?.arg?.tab === 'school') {
              state.trainer = []
            } else {
              state.trainer = action.payload.data
            }
          }
        }
      })
      .addCase(customerCheckExam.fulfilled, (state, action) => {
        if (action.type === 'evaluate/customerCheckExam/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.data = action.payload.data
            state.result = action.payload.result
            state.detail = action.payload.detail
          }
        }
      })
  }
})

export const { sizingHandle, clearAuth, setReady, setAnswer, evaluateAlert, clearEvaluate, evaluateSubmit, evaluateState, needCheck } = evaluateSlice.actions


export default evaluateSlice.reducer
