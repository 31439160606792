// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../configs/apiConfig'

// export const registerReaderCard = createAsyncThunk('register/registerReaderCard', async () => {
//   const response = await apiHandle('POST', 'mockreadercard')
//   return response
// })

// export const getDataTable = createAsyncThunk('register/getDataTable', async (params) => {
//   const response = await apiHandle('GET', 'application/gettable', params)
//   return response
// })
// customer/check
export const customerCheck = createAsyncThunk('testing/customerCheck', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const customerLogin = createAsyncThunk('testing/customer/login', async (params) => {
  const response = await apiHandle('POST', 'customer/login', params)
  return response
})

export const customerExam = createAsyncThunk('testing/customerExam', async (params) => {
  const response = await apiHandle('GET', 'customer/exam', params)
  return response
})

export const customerCheckExam = createAsyncThunk('testing/customerCheckExam', async (params) => {
  const response = await apiHandle('POST', 'customer/checkresultexam', params)
  return response
})


export const testingSlice = createSlice({
  name: 'testing',
  initialState: {
    auth: null,
    sizing: 0,
    data: [],
    isReady: false,
    application_id: null,
    result: null,
    detail: null,
    alert: {
      active: false,
      message: '',
      err: false
    }
  },
  reducers: {
    sizingHandle: (state, action) => {
      if (action.type === 'testing/sizingHandle') {
        console.log(action)
        state.sizing = action.payload
      }
    },
    clearAuth: (state, action) => {
      if (action.type === 'testing/clearAuth') {
        state.auth = null
      }
    },
    setReady: (state, action) => {
      if (action.type === 'testing/setReady') {
        state.isReady = action.payload
      }
    },
    setAnswer: (state, action) => {
      if (action.type === 'testing/setAnswer') {
        if (action.payload.index && action.payload.value) {
          state.data[action.payload.index - 1].client_answer = action.payload.value
        }
      }
    },
    testingAlert: (state, action) => {
      if (action.type === 'testing/testingAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(customerCheck.fulfilled, (state, action) => {
        if (action.type === 'testing/customerCheck/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            alert(action?.payload?.message)
          } else {
            if (action.payload.data === null) {
              state.auth = undefined
            } else {
              state.auth = action.payload.data
            }

          }
        }
      })
      .addCase(customerLogin.fulfilled, (state, action) => {
        if (action.type === 'testing/customer/login/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            alert(action?.payload?.message)
          } else {
            if (action.payload.data === null) {
              state.auth = undefined
            } else {
              console.log(action.payload)
              state.auth = action.payload.data
            }
           
          }
        }
      })
      
      .addCase(customerExam.fulfilled, (state, action) => {
        if (action.type === 'testing/customerExam/fulfilled') {
          console.log('action', action)
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.data = action.payload.data
            state.application_id = action.meta.arg.application_id
            state.result = null
            state.detail = null
            state.isReady = true
          }
        }
      })
      .addCase(customerCheckExam.fulfilled, (state, action) => {
        if (action.type === 'testing/customerCheckExam/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.data = action.payload.data
            state.result = action.payload.result
            state.detail = action.payload.detail
          }
        }
      })
  }
})

export const { sizingHandle, clearAuth, setReady, setAnswer, testingAlert } = testingSlice.actions


export default testingSlice.reducer
